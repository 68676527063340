.card {
      width: 19rem;
      height: 350px;
}

.card-texto {
      font-size: 14px;
}

.card-especialidades {
      background-color: var(--cinza-escuro);
      border: 1px solid rgba(255, 255, 255, 0.486);
      box-shadow: 0 0 8px rgba(255, 255, 255, 0.363);
}

.card-especialidades:hover {
      box-shadow: 1px 1px 10px 3px rgba(255, 255, 255, 0.363);
}