.img_port {
      position: relative;
      width: 360px;
      height: 460px;
      background-size: cover;
      background-position: 100% 0%;
      border-radius: 40px;
      cursor: pointer;
      transition: 8s;
  }
  
  .img_port:hover {
      background-position: 100% 100%;
  }
  
  .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000000b8;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: 600;
      color: #fff;
      opacity: 0;
      transition: .5s;
  }
  
  .overlay:hover {
      opacity: 1;
  }

  @media screen and (max-width: 420px) {
    .img_port {
        width: 300px;
        height: 400px;
    }
  }