.texto-sobremim {
      text-align: justify;
      color: rgba(255, 255, 255, 0.534);
}

.image-flutuante {
      position: relative;
      animation: flutuarImagem 2s infinite ease-in-out alternate;
}


.icon-redesocial {
      color: var(--branco);
      padding: 10px 15px;
      border: 1px solid rgba(255, 255, 255, 0.466);
      border-radius: 15px;
}

.icon-redesocial:hover {
      border: 1px solid var(--branco);
      transform: scale(1.05);
      transition: .3s;
}

.link-icon-social:hover {
      transform: scale(1.15);
      transition: .3s;
}

@media screen and (max-width: 992px) {
      .image-flutuante {
            width: 360px;
      }
}

@media screen and (max-width: 400px){
      .image-flutuante {
            width: 300px;
      }
}

@keyframes flutuarImagem {
      0% {top: 0px;}
      100% {top: 30px;}
}